<template>
    <div>
        <Verification
            :type="true"
        />
    </div>
</template>

<script>
    import Verification from '../../Verification/Verification';

    export default {
        name: 'Reaccreditation',
        components: {
            Verification
        }
    }
</script>