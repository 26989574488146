<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">

            <h3 class="text-uppercase" v-html="doctorTitle"></h3>

            <hr class="my-4"/>

            <div class="col-lg-12">
                <em class="mb-4 text-danger">
                    Kindly update 
                    <em class="text-primary">{{ fullname }}</em>'s 
                    Qualification(s) below, before proceeding to add more staff... <br>
                    Note: If there is no Qualification for this Staff, Click on Continue button below.
                </em>
                <hr class="my-4" v-if="hasRecords"/>
            </div>

            <!-- import Qualification Data template here -->
            <QualificationData
                :qualifications="qualifications"
                v-on:editRecord="editRecord"
                v-on:deleteRecord="deleteRecord"
                :pagination="pagination"
                :hasPagination="hasPagination"
                :paginate="paginate"
            />

            <div class="col-lg-12">
                <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>
                <base-button
                    v-if="hasRecords && ! showAddRecord"
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>

                <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                    <div class="col-lg-12">
                        <hr class="my-4"/>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Qualification Name"
                            placeholder="Enter Qualification Name"
                            input-classes="form-control-alternative"
                            v-model="record.name"
                            required
                        />
                    </div>

                    <div class="col-lg-6">
                        <base-input
                            label="Qualification Date"
                            addon-left-icon="ni ni-calendar-grid-58"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="config"
                                placeholder="Select Date.."
                                class="form-control datepicker"
                                v-model="record.qual_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <!-- <div class="col-lg-4">
                        <base-input
                            label="End Date"
                            addon-left-icon="ni ni-calendar-grid-58"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="config"
                                placeholder="Select Date.."
                                class="form-control datepicker"
                                v-model="record.end_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div> -->


                    <div class="col-lg-12">
                        <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>
                    </div>

                    <div class="col-lg-12">
                        <base-button
                            type="success"
                            @click="saveQualRecord()"
                        >
                            <i class="fa fa-save"></i>
                            {{isloading ? 'Please Wait...' : updateButton }}
                        </base-button>
                        <base-button
                            type="danger"
                            class="right"
                            @click="displayAddPage()"
                            v-if="qualifications && qualifications.length > 0"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>

                    </div>
                </div>
            </div>

            <!-- this is consulant record template -->
            <!-- <Consultant
                :fullname="fullname"
                :records="records"
                :type="type"
            /> -->

            <div class="col-lg-12">
                <hr class="my-4"/>

                <base-button
                    type="danger"
                    @click="$emit('addQualification', null, false)"
                >
                    <i class="fa fa-times"></i>
                    Go Back
                </base-button>

                <base-button
                    type="success"
                    class="right"
                    @click="$emit('addQualification', null, false)"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Consultant from './Consultant';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import QualificationData from './QualificationData';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            Consultant,
            flatPicker,
            QualificationData
        },
        name: 'Qualifications',
        props: ['records', 'type', 'title', 'id', 'loadHospitalInfo'],
        data() {
            return {
                qualifications: [],
                paginatedItems: [],
                record: {
                    name: null,
                    qual_date: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: false,
                showAddRecord: false,
                newAccount: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.qualifications && this.qualifications.length > 0;
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            },
            fullname() {
                if(this.records && this.records.length > 0) {
                    let record = this.records[0];
                    let name = record.first_name;
                    name += record.other_name ? ' ' + record.other_name : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return this.type;
                }
            },
            doctorTitle() {
                return 'UPDATE <em class="text-danger">' + this.fullname + '</em>\'s QUALIFICATIONS';
            }
        },
        methods: {
            ...mapActions(['updateHospitalData', 'deleteHospitalData']),
            displayAddPage() {
                this.emptyRecords();
                return this.showAddRecord = ! this.showAddRecord;
            },
            editRecord(data) {// edit record
                if(data) {
                    this.record = {
                        name: data.name,
                        qual_date: data.qual_date,
                        id: data.id
                    };
                    this.showAddRecord = true;
                }
            },
            setData(records = null) {// set paginated function
                this.emptyRecords();
                let res;
                if(records) {// if qualifications is passed
                    res = records;
                } else {// get default qualifications
                    res = this.records.length > 0 ? this.records[0].qualifications : []
                }
                if(res && res.length > 0) {
                    this.qualifications = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.qualifications.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.qualifications = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.qualifications = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.qualifications = this.paginatedItems.slice(from, to);
            },
            saveQualRecord() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    if(this.type && (this.type == 'consultant')) {// for consultant
                        this.record.training_hospital_doctor_id = this.records[0].id;
                    } else if(this.type && (this.type == 'nurse')) {// for nurse
                        this.record.training_hospital_nurse_id = this.records[0].id;
                    } else if(this.type && (this.type == 'staff')) {// for nurse
                        this.record.training_hospital_staff_id = this.records[0].id;
                    }
                    let record = { qualification : this.record, id : this.id };
                    this.updateHospitalData(record).then((res) => {
                        this.setData(res.record);
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { name: null, qual_date: null };
                appRecord.qual_date = data.qual_date == null ? this.showInuptError('Qualification Date') : data.qual_date;
                appRecord.name = data.name == null ? this.showInuptError('Qualification Name') : data.name;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    name: null,
                    qual_date: null
                };
            },
            deleteRecord(id) {// delete examination
                this.preloader();// show loading
                let data = { qualification : true , id : id };
                this.deleteHospitalData(data).then((res) => {
                    this.qualifications = res.record;
                    this.setData(res.record);
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>