<template>
    <div class="col-lg-12" v-if="isLoadingBlade">
        <div class="d-flex justify-content-center">
            <img :src="loadingBladeUrl" />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import axios from 'axios';

    export default {
        data() {
            return {
                isLoadingBlade: true
            }
        },
        created() {// call logout function
            this.logout();
        },
        computed: {
        ...mapGetters(['getLogoutUserUrl', 'getUserAccessToken', 'getAuthorizationToken',
            'loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['destroyAccessToken']),
            logout() {
                const header = {Authorization: `Bearer ${this.getAuthorizationToken}`};
                axios.post(this.getLogoutUserUrl, {'access_token' : this.getUserAccessToken},
                    { headers: header })// detroy server token
                    .then(() => {
                        this.destroyAccessToken();// destroy local token
                        return window.location.href='/'; // redirect to login page
                    })
                    .catch(err => {
                        alert(err.message);
                    })
            }
        },
    }
</script>