<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">Request for Verification Visit</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link 
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="fa fa-home"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="! isLoadingBlade">
                            <div class="card shadow">
                                <div class="table-responsive">
                                    <base-table
                                        class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="applications"
                                        v-if="applications && applications.length > 0"
                                    >
                                        <template slot="columns">
                                            <th width="2%">S/N</th>
                                            <th>Request Type</th>
                                            <th>Application Date</th>
                                            <th>Payment Status</th>
                                            <th>Visit Status</th>
                                            <th width="5%">&nbsp;</th>
                                        </template>

                                        <template
                                            slot-scope="{row, index}"
                                        >
                                        <td class="budget">
                                            {{ pagination.from+index}}
                                        </td>
                                        <td class="text-uppercase">
                                            {{ row.request_type ? row.request_type : 'N/A' }}
                                        </td>
                                        <td>
                                            {{ row.application_date | getDateFormat }}
                                        </td>
                                        <td>
                                            <badge :type="getPaymentStatusColor(row.payment_status)">
                                                {{ row.payment_status }}
                                            </badge>
                                        </td>
                                        <td>
                                            <badge :type="getVisitStatusColor(row.visit_status)">
                                                Visit {{ (row.visit_status == 'yes') ? 'Requested' : 'Not Requested' }}
                                            </badge>
                                        </td>
                                        <td>
                                            <base-button
                                                type="success"
                                                size="sm"
                                                @click="requestNow(row)"
                                                :disabled="row.visit_status == 'yes'"
                                            >
                                                <i class="fa fa-history"></i> 
                                                Request Now
                                            </base-button>
                                        </td>

                                        </template>

                                    </base-table>
                                </div>

                                <div
                                    class="card-footer d-flex justify-content-end bg-transparent"
                                    v-if="hasPagination"
                                    @click="paginate"
                                >
                                    <base-pagination
                                        :total="pagination.total"
                                        align="center"
                                        v-model="pagination.number"
                                        :perPage="pagination.perPage"

                                    >
                                    </base-pagination>
                                </div>

                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{ errorMsg }}
                                </span>
                            </base-alert>
                        </div>


                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import Badge from '../../components/Badge.vue';
    import moment from 'moment';
    import swal from 'sweetalert2'

    export default {
        components: { Badge },
        name: 'VerificationVisit',
        data() {
            return {
                applications: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                procedureModal: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            }
        },
        methods: {
            ...mapActions(['fetchHospitalProfile', 'getHospitalApplications', 'getPaginatedApplicationItems', 
                'requestVerifyVisit']),
            loadApplications() {
                const hospitalId = this.$store.state.user.hospitalId;
                let data = { id : hospitalId, limit : this.pagination.perPage, status: 'submitted'};
                this.getHospitalApplications(data).then((res) => {// get applications
                    this.setPages(res.applications);// set pagination
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.applications = [];
                this.paginatedItems = res.data;
                this.applications = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.applications = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.applications && this.applications.length == 0
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!, kindly apply for one';
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending' || status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            getPaymentStatusColor(status) {
                if(status == 'paid') {
                    return 'success';
                } else {
                    return 'danger';
                }
            },
            getVisitStatusColor(status) {
                return (status == 'yes') ? 'success' : 'danger';
            },
            requestNow(app) {// request for visitation
                swal.fire({
                    title: 'Are you sure?',
                    text: "You want to request for a Verification Visit!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRequestApiCall(app);// make api call
                    }
                });
            },
            makeRequestApiCall(app) {
                this.showInputErrorStatus = false;
                let comment = app.visit_comment ? app.visit_comment : '';
                swal.fire({
                    title: 'Verification Visit Request',
                    html:
                        '<br>'+
                        '<label><b>Request Comment</b></label><br>' +
                        '<textarea id="swal-input1" class="swal2-input" style="height: 150px; padding: 5px;">'
                        + comment +'</textarea><br>',
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed',
                    cancelButtonText: 'No, cancel!',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let data =  {
                            id : app ? app.id : null,
                            visit_comment : document.getElementById('swal-input1').value
                        };
                        if(data && data.id) {//update
                            return this.requestVerifyVisit(data).then((response) => {
                                if (!response.status) {
                                throw new Error(response.msg)
                                }
                                return response
                            }).
                            catch((error) => {
                                swal.showValidationMessage(
                                `Request failed: ${error}`
                                )
                            });
                        } else {
                        swal.showValidationMessage(
                                `Request failed: Kindly reload page and try again`
                            )
                        }
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Request Successful!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadApplications();// refresh items
                    }
                });
            }
        },
        created() {
            this.loadApplications();
            this.fetchHospitalProfile();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
