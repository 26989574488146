<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Support Staff Details</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12" v-if="records && records.length > 0">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="text-uppercase">
                            <td width="5%">
                                <h4>#</h4>
                            </td>
                            <td width="20%">
                                <h4>Name</h4>
                            </td>
                            <td width="20%">
                                <h4>Previous Name</h4>
                            </td>
                            <td width="15%">
                                <h4>Department</h4>
                            </td>
                            <td width="15%">
                                <h4>Unit</h4>
                            </td>
                            <td width="10%">
                                <h4>Gender</h4>
                            </td>
                            <td width="15%">
                                <h4>Designation</h4>
                            </td>
                        </thead>
                    </table>
                </div>
                <div
                    class="table-responsive"
                    v-for="(record, index) in records"
                    :key="record.id"
                >
                    <table class="table table-bordered">
                        <tbody>
                            <td width="5%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ index+1 }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ getFullName(record) }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.previous_name ? record.previous_name : 'N/A' }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.department && record.department.name ? record.department.name : 'N/A' }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.unit ? record.unit : 'N/A' }}
                                </h4>
                            </td>
                            <td width="10%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.gender ? record.gender : 'N/A' }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.designation ? record.designation : 'N/A' }}
                                </h4>
                            </td>
                        </tbody>
                    </table>

                    <div
                        v-if="record && record.qualifications && record.qualifications.length > 0"
                        style="margin-left: 8%;"
                    >
                        <br>
                        <div class="col-lg-12">
                            <h4 class="mb-0 text-uppercase">
                                <em class="text-danger">{{ getFullName(record) }}</em>
                                's Qualifications
                            </h4>
                        </div>

                        <table class="table table-bordered">
                            <thead class="text-uppercase">
                                <td>
                                    <h4>S/N</h4>
                                </td>
                                <td>
                                    <h4>Qualification Name</h4>
                                </td>
                                <td>
                                    <h4>Qualification Date</h4>
                                </td>
                            </thead>
                            <tbody
                                v-for="(qual , index) in record.qualifications"
                                :key="qual.id"
                            >
                                <td>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ index+1 }}
                                    </h4>
                                </td>
                                <td>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ qual.name ? qual.name : 'N/A' }}
                                    </h4>
                                </td>
                                <td>
                                    <h4 class="text-uppercase font-weight-light">
                                        {{ qual.qual_date | getDateFormat }}
                                    </h4>
                                </td>
                            </tbody>
                        </table>
                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Support Staff Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'StaffDetails',
        props: [ 'records' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_name ? ' ' + record.other_name : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>