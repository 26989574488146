<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">My Applications</h3>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="! isLoadingBlade">
                            <div class="card shadow">
                                <div class="table-responsive">
                                    <base-table
                                        class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="applications"
                                        v-if="applications && applications.length > 0"
                                    >
                                        <template slot="columns">
                                            <th width="2%">S/N</th>
                                            <th>Training Type <br> / Request Type</th>
                                            <th>Application<br> Date</th>
                                            <th>Status</th>
                                            <th>Payment<br> Status</th>
                                            <th>Approve/<br>Reject Date</th>
                                            <th width="5%">&nbsp;</th>
                                        </template>

                                        <template
                                            slot-scope="{row, index}"
                                        >
                                        <td class="budget">
                                            {{ pagination.from+index}}
                                        </td>
                                        <td class="text-capitalize">
                                            {{ row.training_type ? row.training_type+' Training' : 'N/A' }}<br>
                                            <badge type="info">
                                                {{ row.request_type ? row.request_type : 'N/A' }}
                                            </badge>
                                        </td>
                                        <td>
                                            {{ row.application_date | getDateFormat }}
                                        </td>
                                        <td>
                                            <badge :type="getStatusColor(row.status)">
                                                {{ row.status }}
                                            </badge>
                                        </td>
                                        <td>
                                            <badge :type="getPaymentStatusColor(row.payment_status)">
                                                {{ row.payment_status }}
                                            </badge>
                                        </td>
                                        <td>
                                            {{ row.approved_date | getDateFormat }}
                                        </td>
                                        <td>
                                            <router-link
                                                :to="'/application-details/'+row.id"
                                                class="btn btn-sm btn-success"
                                                style="margin-bottom: 4px;"
                                            >
                                                <i class="fa fa-eye"></i> Details
                                            </router-link>

                                            <form
                                                :action="getRootUrl+'/print-hospital-letter'"
                                                method="POST"
                                                target="_blank"
                                                v-if="row && row.letter"
                                            >
                                                <input type="hidden" name="id" :value="row.id" />
                                                <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                                <input type="hidden" name="option" value="user" />
                                                <input type="hidden" name="hospital_id" :value="hospital.id" />
                                                <input type="hidden" name="date" :value="row.approved_date" />
                                                <input type="hidden" name="ref_no" :value="row.letter.ref_no" />
                                                <input type="hidden" name="last_quota" :value="row.letter.last_quota" />
                                                <input type="hidden" name="plenary" :value="row.letter.plenary" />
                                                <input type="hidden" name="plenary_date" :value="row.letter.plenary_date" />
                                                <input type="hidden" name="plenary_venue" :value="row.letter.plenary_venue" />
                                                <input type="hidden" name="visit_date" :value="row.letter.visit_date" />
                                                <input type="hidden" name="quota" :value="row.letter.quota" />

                                                <base-button
                                                    type="primary"
                                                    class="btn btn-sm"
                                                    nativeType="submit"
                                                >
                                                    <i class="fa fa-print"></i>
                                                    Print Letter
                                                </base-button>
                                            </form>
                                        </td>

                                        </template>

                                    </base-table>
                                </div>

                                <div
                                    class="card-footer d-flex justify-content-end bg-transparent"
                                    v-if="hasPagination"
                                    @click="paginate"
                                >
                                    <base-pagination
                                        :total="pagination.total"
                                        align="center"
                                        v-model="pagination.number"
                                        :perPage="pagination.perPage"

                                    >
                                    </base-pagination>
                                </div>

                            </div>
                        </div>


                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import Badge from '../../components/Badge.vue';
    import moment from 'moment';

    export default {
        components: { Badge },
        name: 'Applications',
        data() {
            return {
                applications: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                procedureModal: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'hospital', 'getAuthorizationToken', 'getRootUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            }
        },
        methods: {
            ...mapActions(['fetchHospitalProfile', 'getHospitalApplications', 'getPaginatedApplicationItems']),
            loadApplications() {
                const hospitalId = this.$store.state.user.hospitalId;
                let data = {id : hospitalId, limit : this.pagination.perPage};
                this.getHospitalApplications(data).then((res) => {// get applications
                    this.setPages(res.applications);// set pagination
                    this.isLoadingBlade = false;
                    // this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.applications = [];
                this.paginatedItems = res.data;
                this.applications = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.applications = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.applications && this.applications.length == 0
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!, kindly apply for one';
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending' || status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            getPaymentStatusColor(status) {
                if(status == 'paid') {
                    return 'success';
                } else {
                    return 'danger';
                }
            }
        },
        created() {
            this.loadApplications();
            this.fetchHospitalProfile();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
