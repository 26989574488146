<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Description</th>
                            <th>Transaction Date</th>
                            <th>Amount</th>
                            <th>RRR</th>
                            <th>Status</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row}"
                        >
                        <td class="budget">
                            {{ pagination.from}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.training_type ? row.training_type + ' Training' : 'N/A' }}<br>
                            <badge type="success">
                                {{ row.request_type ? row.request_type : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{  getTransactionDate(row.transaction) }}
                        </td>
                        <td>
                            {{row.transaction ? formatPrice(row.transaction.amount) : 'N/A'}}
                        </td>
                        <td class="text-uppercase">
                            {{ row.transaction ? row.transaction.payment_ref : 'N/A' }}
                        </td>
                        <td>
                            <badge :type="getColor(row.transaction)">
                                {{ row.transaction ? row.transaction.status : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <form :action="printReceiptUrl" method="POST" target="_blank">
                                <input type="hidden" name="id" :value="row.transaction.id" />
                                <base-button
                                    type="info"
                                    size="sm"
                                    nativeType="submit"
                                >
                                    <i class="fa fa-print"></i>
                                    Print Receipt
                                </base-button>
                            </form>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'PaymentData',
        props: ["records", "pagination", "hasPagination", "paginate"],
        computed: {
            ...mapGetters(['printReceiptUrl'])
        },
        methods: {
            getTransactionDate(transaction) {// get & format date
                if(transaction) {
                    return transaction.date_paid != null ? this.getDateFormat(transaction.date_paid) :
                        this.getDateFormat(transaction.date_generated);
                } else {
                    return 'N/A';
                }
            },
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            },
            getColor(transaction) {
                if(transaction) {
                    return transaction.status == 'PAID' ? 'success' : 'danger';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>