<template>
    <div>
        <div class="col-lg-12" v-if="! showAddRecord && qualifications && qualifications.length > 0">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="qualifications"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Qualification Name</th>
                            <th>Qualification Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.name }}
                        </td>
                        <td class="text-capitalize">{{ row.qual_date | getDateFormat }}</td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="$emit('editRecord', row)"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                @click="$emit('deleteRecord', row.id)"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'QualificationData',
        props: ["qualifications", "showAddRecord", "pagination", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        }
    }
</script>