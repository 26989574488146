<template>
    <div>
        <base-header type="gradient-danger" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->



            <div class="row" v-if="! isLoadingBlade && hospital">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Approved Quota"
                                type="gradient-danger"
                                :sub-title="(hospital.quota ? hospital.quota.toString() : 'N/A')"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0 green"
                    >
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Last accreditation Date"
                                type="gradient-danger"
                                :sub-title="lastAccreditDate"
                                icon="ni ni-satisfied"
                                class="mb-4 mb-xl-0 yellow"
                    >
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <stats-card title="accreditation expiration Date"
                                type="gradient-danger"
                                :sub-title="expiryDate"
                                icon="ni ni-support-16"
                                class="mb-4 mb-xl-0 red"
                    >
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 padding30">
            <div class="row">
                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <div class="row justify-content-center">
                              <div class="col-lg-3 order-lg-2">
                                  <div class="card-profile-image">
                                      <router-link to="/profile">
                                          <img
                                            :alt="hospital.name"
                                            :src="hospitalPhotoUrl+hospital.photo"
                                            @error="replaceByDefault"
                                            class="rounded-circle image"
                                          >
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                              <div class="d-flex justify-content-between">
                                  <router-link
                                    to="/requirements-policy"
                                    class="mr-4 btn btn-sm btn-primary"
                                    >
                                      Requirements
                                    </router-link>
                                  <base-button
                                    size="sm" type="default"
                                    class="float-right text-capitalize"
                                  >
                                      {{ hospital.type }} Training
                                  </base-button>
                              </div>
                          </div>
                          <div class="card-body pt-0 pt-md-4" v-if="hospital">
                              <div class="row">
                                  <div class="col">
                                      <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                      </div>
                                  </div>
                              </div>
                              <div class="text-center">
                                  <h2 class="text-capitalize">
                                      {{ hospital.name }}
                                  </h2>
                                  <h3 class="text-capitalize">
                                      <badge size="sm"
                                        :type="hospital.approved_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ hospital.approved_status }}
                                      </badge>
                                  </h3>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Email Address
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ hospital.email }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Phone Number
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ hospital.phone }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Year Of Establishment
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ hospital.establishment }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Location
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ hospital.location }}
                                        </badge>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <!-- End charts-->

            <!-- Reject app template here -->
            <div class="col-md-4" :show="showRejectModal">
              <modal :show="showRejectModal"
                    gradient="danger"
                    modal-classes="modal-danger modal-dialog-centered">
                  <h4
                    slot="header" class="modal-title"
                    id="modal-title-notification"
                  >
                    Importance Notice
                  </h4>

                  <div class="py-3 text-center">
                      <i class="ni ni-bell-55 ni-3x"></i>
                      <h4 class="heading mt-4">Your Application Rejected!</h4>
                      <p>
                        <strong>Note:</strong> Your Application was rejected, kindly click on the link below
                        to make corrections and submit accordingly
                      </p>
                  </div>

                  <template slot="footer">
                      <a 
                        :href="'/pending-application/' + hospital.app_reject_id"
                        class="btn btn-sm btn-white"
                      >
                        Check Now
                      </a>

                      <base-button type="link"
                                  text-color="white"
                                  class="ml-auto"
                                  @click="showRejectModal = false">
                          Close
                      </base-button>
                  </template>
              </modal>
            </div>


        </div>
    </div>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';


  export default {
    name: 'Dashboard',
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['hospital', 'loadingBladeUrl', 'blankImageUrl', 'hospitalPhotoUrl']),
      expiryDate() {// get accreditation expiration date
        let date = this.hospital && this.hospital.expiry_date ? this.hospital.expiry_date : false;
        return date ? moment(date).format('Do MMM, YYYY').toString() : 'N/A';
      },
      lastAccreditDate() {// get last accreditation date
        let date = this.hospital && this.hospital.last_accreditation ? this.hospital.last_accreditation : false;
        return date ? moment(date).format('Do MMM, YYYY').toString() : 'N/A';
      }
    },
    data() {
      return {
        modal: false,
        profileInfo: [],
        isLoadingBlade: true,
        showRejectModal: false
      };
    },
    methods: {
      ...mapActions(['fetchHospitalProfile']),
      stopLoading(res) {
        var self = this;
        setTimeout(function() { self.isLoadingBlade = false; }, 1000);
        self.showRejectModal = res.app_reject_id ? true : false;// show reject modal function
      },
      checkStatus() {// check for user account status
        this.fetchHospitalProfile().then((res) => {
            if(res && (res.status == 'new')) {
              return window.location.href = '/verification-payment';
            } else {
              this.stopLoading(res);
            }
        });
      },
      replaceString(value) {
          if(value) {
            return value.replace("-", " ");
          } else {
            return 'N/A';
          }
      },
      replaceByDefault(e) {
          return e.target.src = this.blankImageUrl;
      }
    },
    mounted() {
      this.fetchHospitalProfile();// get profile
      this.checkStatus();
    }
  };
</script>
<style scoped>
  .padding30 {
    padding: 30px;
  }
  .fontSize15 {
    font-size: 15px;
  }
  .red {
    background-color: #efd2d2;
  }
  .green {
    background-color: #bcf9c6;
  }
  .yellow {
    background-color: #e8eb4b;
  }
  .left {
    float: left;
  }
  .image {
    width: 170px;
    height: 170px;
  }
</style>

<style>
  .modal-footer {
    margin-top: -50px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
  }
</style>
