<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Facility Details</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive" v-if="records && records.length > 0">
                <table class="table table-bordered">
                    <thead class="text-uppercase">
                        <td>
                            <h4>S/N</h4>
                        </td>
                        <td>
                            <h4>Name of Facility</h4>
                        </td>
                        <td>
                            <h4>No of Facility</h4>
                        </td>
                        <td>
                            <h4>Ward</h4>
                        </td>
                        <td>
                            <h4>Department</h4>
                        </td>
                    </thead>
                    <tbody
                        v-for="(record , index) in records"
                        :key="record.id"
                    >
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ index+1 }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.name ? record.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.number ? record.number : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.ward ? record.ward : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.department ? record.department : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Facility Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FacilityDetails',
        props: [ 'records' ],
        methods: {
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_name ? ' ' + record.other_name : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>