<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Hospital Profile Details</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.transaction ? application.transaction.payment_ref : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.name ? profile.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.email ? profile.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Phone No</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.phone ? profile.phone : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Year of <br>establishment</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.establishment ? profile.establishment : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Training Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.training_type ? application.training_type+' Training' : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Location</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.location ? profile.location : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Request Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.request_type ? application.request_type : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of First <br>Accreditation</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ application.first_accreditation | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of Last <br>Accreditation</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ application.last_accreditation | getDateFormat }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Type of Hospital</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.hospital_type ? profile.hospital_type : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Additional <br>Accreditation Notes</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.additional_notes ? application.additional_notes : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PersonalDetails',
        props: [ 'application' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            profile() {
                return this.application ? this.application.training_hospital : {};
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>