<template>
    <div>
        <div v-if="! isSubmitted">
            <div v-if="! isLoadingBlade && ! showErrorStatus">
                <h3 class="text-uppercase">Verification Application Preview</h3>
                <em class="mb-4 text-danger">
                    Kindly check all information supplied, after submission changes can't be made...
                </em>
                <hr>

                <!-- This is profile details template here -->
                <ProfileDetails
                    :application="application"
                    v-if="application"
                />

                <!-- This is consultant details template here -->
                <ConsultantDetails
                    :records="application.training_hospital.doctors"
                    v-if="application && application.training_hospital && application.training_hospital.doctors"
                />

                <!-- This is nurse details template here -->
                <NurseDetails
                    :records="application.training_hospital.nurses"
                    v-if="application && application.training_hospital && application.training_hospital.nurses"
                />

                <!-- This is staff details template here -->
                <StaffDetails
                    :records="application.training_hospital.staff"
                    v-if="application && application.training_hospital && application.training_hospital.staff"
                />

                <!-- This is facility details template here -->
                <FacilityDetails
                    :records="application.training_hospital.facilities"
                    v-if="application && application.training_hospital && application.training_hospital.facilities"
                />

            </div>

            <div class="col-lg-12">
                <h3 class="mb-4 text-danger">
                    Kindly check all information before hitting the submit button, changes can't be made after submission.
                </h3>
                <hr>
            </div>


            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">{{errorMsg}}</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12" v-if="! isLoadingBlade">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>

                <base-button
                    type="success"
                    class="right"
                    @click="submitNow()"
                >
                    <i class="fa fa-save"></i>
                    {{ isloading ? 'Please Wait...' : 'Submit Now'}}
                </base-button>
            </div>
        </div>

        <div class="col-lg-12" v-if="isSubmitted">
            <div class="d-flex justify-content-center">
                <img :src="completeImageUrl" />
            </div>
            <br>
            <h2 class="text-uppercase text-center">
                You have completed the application process
            </h2>
            <h4 class="text-uppercase text-center text-danger">
                Note: You will be contacted subsequently by MDCN
            </h4>
            <hr>
            <div class="text-center">
                <router-link
                    to="/"
                    class="btn btn-lg btn-success"
                >
                    <i class="fa fa-home"></i>
                    Dashboard Now
                </router-link>
            </div>
            <br><br>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ProfileDetails from './ProfileDetails';
    import ConsultantDetails from './ConsultantDetails';
    import NurseDetails from './NurseDetails';
    import StaffDetails from './StaffDetails';
    import FacilityDetails from './FacilityDetails';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'VerificationSummary',
        props: ['displayFacilityData', 'application', 'loadHospitalInfo'],
        components: {
            ProfileDetails,
            ConsultantDetails,
            NurseDetails,
            StaffDetails,
            FacilityDetails
        },
        data() {
            return {
                isLoadingBlade: false,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isSubmitted: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'completeImageUrl'])
        },
        methods: {
            ...mapActions(['submitVerificationApplication']),
            showPreviousPage() {// go to next page
                return this.displayFacilityData(true);
            },
            submitNow() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeSubmitApiCall();// make api call
                    }
                });
            },
            makeSubmitApiCall() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                if(this.application.id) {// submit
                    this.submitVerificationApplication(this.application.id).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Application Submitted!", res.msg, "success");
                            this.isSubmitted = true;
                        } else {
                            let msg = "An error occurred while submitting form, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>