<template>
    <div>
        <h3 class="text-uppercase">{{ title }}</h3>

        <hr class="my-4"/>

        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th>{{ type }} Name</th>
                            <th v-if="(type == 'consultant')">Folio No</th>
                            <th>Gender</th>
                            <th>Designation</th>
                            <th v-if="(type == 'consultant')">Department</th>
                            <th v-if="(type == 'staff')">Department / Unit</th>
                            <th v-if="(type == 'consultant')">Registration No <br> / AQ Number</th>
                        </template>

                        <template
                            slot-scope="{row}"
                        >
                        <td class="text-capitalize">
                            {{ getFullName(row) }}
                        </td>
                        <td v-if="(type == 'consultant')">{{ row.folio_number }}</td>
                        <td class="text-capitalize">
                            {{ row.gender }}
                        </td>
                        <td class="text-capitalize">{{ row.designation }}</td>
                        <td v-if="(type == 'consultant')">
                            {{ row.department ? row.department.name : 'N/A' }}
                        </td>
                        <td v-if="(type == 'staff')">
                            {{ row.department ? row.department : 'N/A' }}
                        </td>
                        <td v-if="(type == 'consultant')">
                            {{ row.reg_no }} <br>
                            <badge type="success">{{ row.aq_no }}</badge>
                        </td>
                        </template>

                    </base-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Consultant',
        props: ['records', 'title', 'type'],
        methods: {
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_name ? ' ' + record.other_name : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            }
        }
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>