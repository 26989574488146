<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Hospital Profile</h3>
            <hr>
            <div class="row">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Hospital's Name"
                        placeholder="Enter Hospital's Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Hospital's Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Hospital's Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Address of Hospital (Without State / Origin)"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Location"
                            v-model="record.location"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Year of establishment of Hospital"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.establishment"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="year in  years"
                                :key="year"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Internship Training Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.training_type"
                            disabled
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="medical">Medical Training</option>
                            <option value="dental">Dental Training</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Request Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.request_type"
                            @change="updateAccreditation"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="accreditation">Accreditation (For New Institutions)</option>
                            <option value="re-accreditation">Re-accreditation (For Existing Institutions)</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="isReAccreditation">
                    <base-input
                        label="Date of First Accreditation"
                        addon-left-icon="ni ni-calendar-grid-58"
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.first_accreditation"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="isReAccreditation">
                    <base-input
                        label="Date of Last Accreditation"
                        addon-left-icon="ni ni-calendar-grid-58"
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="config"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.last_accreditation"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Type of Hospital"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.hospital_type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="private">Private Hospital</option>
                            <option value="federal">Federal Hospital</option>
                            <option value="state">State Hospital</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Any other information considered necessary for the Accreditation decision"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Additional Accreditation Notes"
                            v-model="record.additional_notes"
                        ></textarea>
                    </base-input>
                </div>

            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="savePersonalData()"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';

    export default {
        name: 'ProfileData',
        props: ['locations', 'displayConsultantData', 'data', 'transaction', 'loadHospitalInfo', 'id'],
        components: {
            flatPicker,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    establishment: null,
                    training_type: null,
                    request_type: null,
                    first_accreditation: null,
                    last_accreditation: null,
                    hospital_type: null,
                    additional_notes: null
                },
                tel: {
                    phone: null,
                    code: 'NG',
                },
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            rrr() {
                return this.transaction  ? this.transaction.payment_ref : null;
            },
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            teachingHospital() {
                return this.data && this.data.training_hospital &&
                    this.data.training_hospital.teaching_hospital ? this.data.training_hospital.teaching_hospital : {};
            },
            isReAccreditation() {// check if request type is re-accreditation
                return this.record.request_type == 're-accreditation';
            }
        },
        methods: {
            ...mapActions(['updateHospitalData']),
            updateAccreditation() {// update accreditation dates
                this.record.first_accreditation = null;
                this.record.last_accreditation = null;
            },
            setProfileData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.data) {
                    let application = this.data;
                    let hospital = application.training_hospital;
                    this.record = {
                        name: hospital.name,
                        email: hospital.email,
                        phone: hospital.phone,
                        phone_code: hospital.phone_code,
                        location: hospital.location,
                        establishment: hospital.establishment,
                        hospital_type: hospital.hospital_type,
                        training_type: hospital.type,
                        request_type: application.request_type,
                        first_accreditation: application.first_accreditation,
                        last_accreditation: application.last_accreditation,
                        additional_notes: application.additional_notes
                    };
                    this.record.name = this.record.name ? this.record.name : this.teachingHospital.name;
                    this.loadPhoneNumber();
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            savePersonalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    let data = { profile_data : this.record , id : this.id };
                    this.updateHospitalData(data).then((res) => {
                        this.loadHospitalInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.displayConsultantData(true);
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    establishment: null,
                    training_type: null,
                    request_type: null,
                    first_accreditation: null,
                    last_accreditation: null,
                    hospital_type: null,
                    additional_notes: null
                };
            },
            validatedData() {
                let data = this.record;
                data.additional_notes != null ? data.additional_notes : this.showInuptError('Additional Accreditation Notes');
                data.hospital_type != null ? data.hospital_type : this.showInuptError('Type of Hospital');
                ((data.last_accreditation != null) || ! this.isReAccreditation) ? true : this.showInuptError('Date of Last Accreditation');
                ((data.first_accreditation != null) || ! this.isReAccreditation) ? true : this.showInuptError('Date of First Accreditation');
                data.request_type != null ? data.request_type : this.showInuptError('Request Type');
                data.training_type != null ? data.training_type : this.showInuptError('Training Type');
                data.establishment != null ? data.establishment : this.showInuptError('Year of Establishment');
                data.location != null ? data.location : this.showInuptError('Location of Hospital');
                data.phone != null ? data.phone : this.showInuptError('Phone Number');
                data.name != null ? data.name : this.showInuptError('Name');
                let status = data.name && data.phone && data.location && data.establishment && data.training_type
                    && ((data.first_accreditation && data.last_accreditation) || ! this.isReAccreditation)  
                    && data.hospital_type && data.additional_notes && data.request_type;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setProfileData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>