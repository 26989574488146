<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showQualPage">
            <h3 class="text-uppercase">Support Staff Information</h3>

            <em class="mb-4 text-danger">
                Kindly add personnels below...
            </em>

            <hr class="my-4"/>

            <!-- import institution details template here -->
            <StaffRecords
                :consultantRecords="consultantRecords"
                :showAddRecord="showAddRecord"
                v-on:editRecord="editRecord"
                v-on:deleteRecord="deleteRecord"
                v-on:addQualification="addQualification"
                :pagination="pagination"
                :hasPagination="hasPagination"
                :paginate="paginate"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Department"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.posting_department_id"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="department in departments"
                                :value="department.id"
                                :key="department.id"
                            >
                                {{department.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Surname"
                        placeholder="Enter Surname"
                        input-classes="form-control-alternative"
                        v-model="record.surname"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Name"
                        placeholder="Enter Other Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Designation"
                        placeholder="Enter Designation"
                        input-classes="form-control-alternative"
                        v-model="record.designation"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Unit"
                        placeholder="Enter Unit"
                        input-classes="form-control-alternative"
                        v-model="record.unit"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>
                <div class="col-lg-12"></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveDoctorRecord()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : updateButton }}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! hasRecords"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>
        </div>

        <Qualifications
            :id="id"
            :records="staff"
            :type="'staff'"
            :title="'Support Staff Information'"
            v-on:addQualification="addQualification"
            :loadHospitalInfo="loadHospitalInfo"
            v-if="showQualPage && staff"
        />

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import StaffRecords from './StaffRecords/StaffRecords';
    import Qualifications from './Qualifications/Qualifications';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            StaffRecords,
            Qualifications
        },
        name: 'StaffData',
        props: ['loadHospitalInfo', 'application', 'id', 'displayNursesData', 'displayFacilityData', 'dept'],
        data() {
            return {
                consultantRecords: [],
                paginatedItems: [],
                record: {
                    posting_department_id: null,
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    designation: null,
                    unit: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                newAccount: true,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                },
                showQualPage: false,
                staff: []
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.consultantRecords && this.consultantRecords.length > 0;
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            },
            departments() {
                let items = [];
                let type = this.application && this.application.training_type ? this.application.training_type : null;
                for(let record of this.dept) {
                    let catArray = record.category ? Object.values(JSON.parse(record.category)) : [];// get category array
                    if(record.type == type && catArray.includes('hospital')) {
                        items.push(record);
                    }
                }
                return items;
            },
        },
        methods: {
            ...mapActions(['updateHospitalData', 'deleteHospitalData']),
            displayAddPage() {
                this.emptyRecords();
                return this.showAddRecord = ! this.showAddRecord;
            },
            editRecord(data) {// edit record
                if(data) {
                    this.record = {
                        posting_department_id: data.posting_department_id,
                        surname: data.surname,
                        first_name: data.first_name,
                        other_name: data.other_name,
                        previous_name: data.previous_name,
                        gender: data.gender,
                        designation: data.designation,
                        unit: data.unit,
                        id: data.id
                    };
                    this.showAddRecord = true;
                }
            },
            loadStaffRecords() {
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.application);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/';
                }
            },
            setData(application) {// set paginated function
                if(application && application.training_hospital) {
                    let res = application.training_hospital.staff;
                    this.consultantRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.consultantRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.consultantRecords = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.consultantRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.consultantRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayNursesData(true);
            },
            showNextPage() {// go to next page
                return this.displayFacilityData(true);
            },
            saveDoctorRecord() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    let record = { staff_data : this.record , id : this.id };
                    this.updateHospitalData(record).then((res) => {
                        // this.loadHospitalInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.addQualification(res.record, true);// show Doctor Qualifications 
                            // this.loadStaffRecords();// load appointments
                            // this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { posting_department_id: null, surname: null, first_name: null, gender: null, 
                                    designation: null, unit: null };
                appRecord.unit = data.unit == null ? this.showInuptError('Unit') : data.unit;
                appRecord.designation = data.designation == null ? this.showInuptError('Designation') : data.designation;
                appRecord.gender = data.gender == null ? this.showInuptError('Gender') : data.gender;
                appRecord.first_name = data.first_name == null ? this.showInuptError('First Name') : data.first_name;
                appRecord.surname = data.surname == null ? this.showInuptError('Surname') : data.surname;
                appRecord.posting_department_id = data.posting_department_id == null 
                                                ? this.showInuptError('Department') : data.posting_department_id;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    posting_department_id: null,
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    designation: null,
                    unit: null
                };
            },
            deleteRecord(id) {// delete examination
                this.preloader();// show loading
                let data = { staff_data : true , id : id };
                this.deleteHospitalData(data).then((res) => {
                    this.loadHospitalInfo();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadStaffRecords(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadStaffRecords(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadStaffRecords(); // load appointments
                });
            },
            addQualification(data, status) {
                this.staff = [];
                if(status) {
                    this.staff.push(data);
                    this.showQualPage = status;
                } else {
                    this.loadHospitalInfo();
                    this.showQualPage = status;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadStaffRecords();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>